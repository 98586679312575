import { tw } from '@/utils/tw';
import BaseAdunitTheme from 'base/components/Adunit/theme';

const Adunit = tw.theme({
  extend: BaseAdunitTheme,
  slots: {
    base: `z-10 w-full text-xs leading-6.5`,
  },
});

export default Adunit;
